<template>
  <div style="min-width: 1200px;">
    <div class="fgc" v-if="showCard"></div>
    <headea style=" min-width: 1200px;"></headea>
    <div class="cont">
      <div class="limg">
        <el-carousel
          height="700px"
          interval="5000"
          arrow="always"
          min-width="1200px"
          style="max-height:55rem;min-height: 700px;"
        >
          <el-carousel-item
            v-for="(item, index) in imgac"
            :key="index"
            style="width: 100%;height: 700px；max-height: 55rem;"
          >
            <img
              style="width: 100%;height:55.5rem;min-height:700px;max-height:700px;"
              :src="item.imgUrl"
              alt=""
            />

            <div v-if="item.title !== ''" class="idx0">
              <div>
                {{ item.title }}
              </div>
          
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 内容 -->
      <div class="contne">
       
      </div>
     
      <!-- 介绍 -->
      <div class="contne">
        <div class="centtwo" v-for="(item, index) in product" :key="index">
          <div class="centtwoimg" v-if="index % 2 == 0">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div style="margin-left: 40px;width:500px" v-if="index % 2 == 0">
            <div
              style="color:#020a8b;font-size:22px;font-weight: bold;margin:20px 0"
            >
              {{ item.title }}
            </div>
            <div style="line-height:32px;margin-bottom:40px">
              {{ item.brief }}
            </div>
            <div class="buttona" @click="cpfw(product[0].id, product[0].title)">
              查看更多
            </div>
          </div>
          <div style="margin-left: 40px;width:600px" v-if="index % 2 == 1">
            <div
              style="color:#020a8b;font-size:22px;font-weight: bold;margin:20px 0"
            >
              {{ product[1].title }}
            </div>
            <div style="line-height:32px;margin-bottom:80px;width:500px">
              {{ product[1].brief }}
            </div>
            <div class="buttona" @click="cpfw(product[1].id, product[1].title)">
              查看更多
            </div>
          </div>
          <div class="centtwoimg" v-if="index % 2 == 1">
            <img :src="product[1].imgUrl" alt="" />
          </div>
        </div>
      </div>
      <div class="contne">
        <div
          style="color:#000a8d;font-size:30px;margin-top:60px;margin-bottom:50px;font-weight: 600;
    text-align: center;"
        >
          <span style="border-bottom:6px solid #000a8d"> 新闻中心</span>
        </div>
        <div class="centerthree">
          <div style="width:480px;height:350px">
            <img :src="newsimg" alt="" />
          </div>
          <div>
            <div
              class="newsbox"
              v-for="(item, index) in newslist"
              :key="index"
              @mouseover="news(item)"
              @click="newsaa(item.id)"
            >
              <div>
                <div style="font-size: 55px;color: #999;">
                  {{ item.updateTime.slice(8, 10) }}
                </div>
                <div style="font-size: 14px;color: #999;margin-top:-10px">
                  {{ item.updateTime.slice(0, 7) }}
                </div>
              </div>
              <div style="margin-left:22px">
                <div class="newstitle" style="font-size: 20px;color: #333333;">
                  {{ item.title }}
                </div>
                <div class="newscentet">{{ item.brief }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin:100px">
          <el-divider>
            <div @click="ckgd" class="buttona">查看更多</div></el-divider
          >
        </div>
      </div>
    </div>
    <bottom style=" min-width: 1200px;"></bottom>
    <sild></sild>
  </div>
</template>

<script>
// import {test} from '../../api/api'
import headea from "../../components/header/index";
import sild from "../../components/sild/side";
import bottom from "../../components/bottom/bottom";
import NProgress from "nprogress";

import { banner, getPage, getDetailNum, productgetPage } from "../../api/api";

export default {
  components: {
    headea,
    sild,
    bottom,
    NProgress,
  },
  data() {
    return {
      page: 1,
      dynamic: null,
      newsimg: "",
      Buttonselection: [],
      imgac: [
    
      ],

      newslist: [],
      imgace: [
      
      ],
      card: "",
      count: "", //倒计时
      showCard: false,
      product: [],
      num: "",
    };
  },
  watch: {},
  created() {
    this.getDetailNum();
    this.bannera();
    this.getListaa();

    
  },
  mounted() {},

  methods: {
    async bannera() {
      let param = {
        type: 2,
      };
      const { data: res } = await banner(param);
      this.imgac = res.data;

      // this.totale = res.data.total;
    },
    async getListaa() {
      let param = {
        pageNo: 1,
        pageSize: 3,
      };
      const { data: res } = await getPage(param);
      this.newslist = res.data.list;
      this.newsimg = this.newslist[0].imgUrl;
      // this.totale = res.data.total;
    },
    async getDetailNum() {
      let param = {};
      const { data: res } = await getDetailNum(param);

      this.num = res.data.productNum;
      console.log(this.num);
      this.getPageaaa();
      // this.totale = res.data.total;
    },

    async getPageaaa() {
      let param = {
        pageNo: 1,
        pageSize: this.num,
      };

      const { data: res } = await productgetPage(param);
      if (res.code == 1) {
        this.product = res.data.list;
      } else {
        this.product = [{ title: 1, imgUrl: "123123" }];
      }
      // this.totale = res.data.total;
    },

    news(e) {
      console.log(1);
      this.newsimg = e.imgUrl;
    },
    gace(item, index) {
      //点击添加字体颜色，其他的删除class名称
      // this.$router.push({
      //   path: item.ptah,
      //   query: {
      //     tape: index,
      //   },
      // });
      // this.dynamic = index;
    },
    cpfw(e, i) {
      this.$router.push({
        path: "/fw",
        query: {
          tape: 0,
        },
      });
      sessionStorage.setItem("cname", i);
      sessionStorage.setItem("fw", e);
    },
    newsaa(e) {
      this.$router.push({
        path: "/xw",
        query: {
          id: e,
          tape: 3,
        },
      });
    },
    showmouseover(index) {
      //点击添加字体颜色，其他的删除class名称

      this.dynamic = index;
    },
    mouseoutshow(index) {
      //点击添加字体颜色，其他的删除class名称

      this.dynamic = null;
    },

    cba(e) {
      // window.open('',"_blank")
      this.$router.push("/lx");
    },

    ckgd() {
      this.$router.push({
        path: "/news",
        query: {
          tape: 3,
        },
      });
    },
    fw(e) {
      this.$router.push({
        path: "/fw",
        query: {
          tape: 2,
        },
      });
      sessionStorage.setItem("fw", e);
    },
   
  },
};
</script>

<style scoped lang="less">
body {
  width: 100%;
}
.cont {
  // max-width: 1920px;

  min-width: 1200px;
  width: 99vw;
  margin: 0 auto;
  margin-top: 100px;
}
.fgc {
  z-index: 9999;
  top: 2px;
  position: absolute;
  width: 100%;
  height: 251%;
  background-color: #fff;
}
.limg {
  position: relative;
  overflow: hidden;
  max-height: 55.5rem;
  min-height: 700px;
  width: 1360px;
  margin: 0 auto;
}

.idx0 {
  position: absolute;
  left: 50%;
  width: 400px;
  height: 104px;
  margin-left: -650px;
  top: 74%;
  font-size: 25px;
  font-weight: 800;
  background-color: #1843a2de;
  color: #fff;
  padding: 0 20px;

  div:nth-child(2) {
    font-size: 60px;
  }
  div:nth-child(3) {
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    span {
      display: inline-block;
      width: 20px;
    }
  }
  div:nth-child(4) {
    font-size: 20px;
    font-weight: 400;
    width: 143px;
    background-color: #c12930;
    margin-top: 60px;
    text-align: center;
    line-height: 46px;
    border-radius: 10px;
    height: 46px;
  }
}


/deep/.el-carousel {
  overflow-y: hidden;
}

.el-carousel--horizontal {
  height: 700px;
}
/deep/.el-carousel__arrow--left {
  display: none !important;
}
/deep/.el-carousel__arrow--right {
  display: none !important;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.contne {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
}
.title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 24px;
  color: #171c61ff;
  font-weight: 600;
}
.cent {
  width: 618px;
  margin: 0 auto;
  word-wrap: break-word;
  font-size: 24px;
  color: #707070ff;
  text-align: center;
  margin-bottom: 85px;
}



.ckgd {
  width: 180px;
  height: 54px;
  background-color: #cc2830ff;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 54px;
  margin: 0 auto;
  margin-top: 42px;
  margin-bottom: 94px;
  cursor: pointer;
}




.cup {
  cursor: pointer;
}
.Buttonselection {
  display: flex;
  font-size: 16px;

  margin: 30px 0;
  justify-content: space-between;
  .Buttonselection-box {
    text-align: center;
    flex: 1;
    cursor: pointer;
    .Buttonselection-img {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      border: 1px solid #999;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
}
.colorChangeae {
  color: #00a0e9;
  .Buttonselection-img {
    background-color: #00a0e9;
  }
}

.centtwo {
  width: 100%;
  height: 336px;
  margin-bottom: 30px;
  font-size: 16px;
  display: flex;
  margin-top: 60px;
  .centtwoimg {
    background-color: #00144f;
    height: 100%;
    width: 600px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
/deep/.el-carousel__button {
  background-color: #00144f;
  width: 40px;
  height: 4px;
}
.buttona {
  width: 120px;
  height: 40px;
  text-align: center;
  background-color: #000a8d;
  color: #fff;
  line-height: 40px;
  cursor: pointer;
  border-radius: 2px;
}
.centerthree {
  height: 350px;
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
  .newsbox {
    margin-left: 60px;
    width: 655px;
    height: 96px;
    display: flex;
    border-bottom: 2px solid #999;
    margin-top: 30px;
    cursor: pointer;
  }
  .newsbox:first-child {
    margin-top: 0;
  }
  .newscentet {
    float: right;
    width: 566px;
    height: 44px;
    line-height: 22px;
    overflow: hidden;
    color: #666;
    margin-top: 16px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .newsbox:hover {
    border-bottom: 2px solid #00144f;

    .newstitle {
      color: #00144f !important;
      font-weight: 600;
    }
  }
}
</style>
